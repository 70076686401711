import Swiper from 'swiper/bundle';

function initTestimonials() {
    let mediaCarouselElem = document.querySelector(".testimonial-swiper");

    // Determine whether or not we need to configure the navigation buttons, and if we do need 
    // them, build the navigation configuration object
    let useNavigation = mediaCarouselElem.querySelectorAll(".swiper-slide").length > 1;
    let navigationSetting = (useNavigation && {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    });

    var mediaSwiper = new Swiper(mediaCarouselElem, {
        loop: true,
        spaceBetween: 0,
        navigation: navigationSetting,
        slidesPerView: 1,
        slidesPerGroup: 1,
        pagination: {
            clickable: true,
            el: '.swiper-pagination',
            type: 'bullets',
        },
        breakpoints: {
            1024: {
                loop: false,
                slidesPerView: 3,
                slidesPerGroup: 3,
                centeredSlides: true,
                spaceBetween: 25,
            }
        }
    });
}

window.initTestimonials = initTestimonials;
